import api from "!../../../../.pnpm/style-loader@2.0.0_webpack@5.98.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/cjs.js!../../../../.pnpm/sass-loader@16.0.5_sass@1.86.0_webpack@5.98.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!./loading.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};